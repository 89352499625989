import React, { useState, useEffect, CSSProperties } from "react";
import {
    Layout,
    Space,
    Button,
    Typography,
    Input,
    Radio,
    RadioChangeEvent,
    Upload,
    UploadProps,
    message,
    notification,
    UploadFile,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./newResourceAdminView.css";
import useToken from "../../utils/useToken";
import { RcFile } from "antd/es/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";

const { Header, Footer, Content } = Layout;
const { Text } = Typography;

export default function NewResourceAdminView() {
    const navigator = useNavigate();
    const tokenController = useToken();
    const [resourceType, setResourceType] = useState("");
    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [role, setRole] = useState("");
    const [file, setFile] = useState<any>({});
    const [pdf, setPDF] = useState<any>({});
    const [richText, setRichText] = useState("");
    const [uploading, setUploading] = useState(false);

    const addNewArticlePDF = () => {
        const formData = new FormData();
        formData.append("file", file.originFileObj as Blob);
        formData.append("pdf", pdf.originFileObj as Blob);
        formData.append("title", title);
        formData.append("author", author);
        setUploading(true);
        // You can use any AJAX library you like
        fetch(
            "https://back-landing-sisefar.azurewebsites.net/api/resource/create/article",
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                },
                body: formData,
            }
        )
            .then((res) => res.json())
            .then(() => {
                setFile({});
                message.success("Artículo y PDF guardados exitosamente.");
                setUploading(false);
            })
            .catch(() => {
                message.error("Error al subir artículo y PDF.");
            })
            .finally(() => {
                setUploading(false);
                navigator("/admin/home");
            });
    };

    const props: UploadProps = {
        customRequest(options) {},
        onChange(info) {
            let uploadStatus = info.file.status;
            setPDF(info.file);
            if (uploadStatus === "uploading") info.file.status = "done";
        },
        accept: ".pdf",
    };

    const coverProps: UploadProps = {
        customRequest(options) {},
        onChange(info) {
            let uploadStatus = info.file.status;
            setFile(info.file);
            if (uploadStatus === "uploading") info.file.status = "done";
        },
        accept: ".png",
    };

    const onChange = (e: RadioChangeEvent) => {
        setPDF({});
        setResourceType(e.target.value);
    };

    const headerStyles: React.CSSProperties = {
        backgroundColor: "#4d4d4d",
        color: "#fff",
    };

    const handleSubmit = () => {
        if (resourceType == "editor") {
            addNewInformativeArticle();
        } else {
            addNewArticlePDF();
        }
    };

    const handleCancel = () => {
        navigator("/admin/home");
    };

    const addNewInformativeArticle = () => {
        const formData = new FormData();
        formData.append("file", file.originFileObj as Blob);
        formData.append("title", title);
        formData.append("content", richText);
        formData.append("author", author);
        setUploading(true);

        fetch(
            "https://back-landing-sisefar.azurewebsites.net/api/resource/create/informative_article",
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                },
                body: formData,
            }
        )
            .then((res) => res.json())
            .then(() => {
                setFile({});
                message.success("Artículo guardado exitosamente.");
                setUploading(false);
                navigator("/admin/home");
            })
            .catch(() => {
                message.error("Error al subir el archivo.");
            })
            .finally(() => {
                setUploading(false);
            });
    };

    const canSaveArticle = (): boolean => {
        return (
            Object.keys(file).length !== 0 &&
            ((richText !== "<p><br></p>" && richText.length !== 0) ||
                Object.keys(pdf).length !== 0) &&
            title !== "" &&
            author !== ""
        );
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Layout>
                <Header style={headerStyles}>Agregar Nuevo Recurso</Header>
                <Layout style={{ height: "70vh" }}>
                    <Content
                        style={{
                            padding: "2%",
                        }}
                    >
                        <div className="field">
                            <Text>Título</Text>
                            <Input
                                placeholder="Título"
                                onChange={(e) =>
                                    setTitle(e.currentTarget.value)
                                }
                            />
                        </div>
                        <div className="field">
                            <Text>Autor</Text>
                            <Input
                                placeholder="Autor"
                                onChange={(e) =>
                                    setAuthor(e.currentTarget.value)
                                }
                            />
                        </div>
                        <div className="field">
                            <Text>Imagen Portada</Text>
                            <br />
                            <Upload {...coverProps}>
                                <Button
                                    icon={<UploadOutlined />}
                                    loading={uploading}
                                >
                                    Subir Imagen
                                </Button>
                            </Upload>
                        </div>

                        <div className="field">
                            <Text>Tipo de Recurso</Text>
                            <br />
                            <Radio.Group
                                onChange={onChange}
                                value={resourceType}
                            >
                                <Radio value={"archivo"}>Archivo</Radio>
                                <Radio value={"editor"}>Editor</Radio>
                            </Radio.Group>
                        </div>
                        {resourceType === "archivo" && (
                            <div className="field">
                                <Text>Archivo por cargar</Text>
                                <br />
                                <Upload {...props}>
                                    <Button
                                        icon={<UploadOutlined />}
                                        loading={uploading}
                                    >
                                        Subir Articulo
                                    </Button>
                                </Upload>
                            </div>
                        )}
                        {resourceType === "editor" && (
                            <ReactQuill
                                modules={{
                                    toolbar: [
                                        [{ header: [1, 2, false] }],
                                        [
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strike",
                                            "blockquote",
                                        ],
                                        [
                                            { list: "ordered" },
                                            { list: "bullet" },
                                            { indent: "-1" },
                                            { indent: "+1" },
                                        ],
                                        ["link", "image"],
                                        ["clean"],
                                    ],
                                }}
                                theme="snow"
                                value={richText}
                                onChange={setRichText}
                            ></ReactQuill>
                        )}
                    </Content>
                </Layout>
                <Footer
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                    }}
                >
                    <div
                        style={{
                            flexGrow: 4,
                        }}
                    />
                    <Button
                        onClick={handleCancel}
                        style={{
                            margin: "0 1%",
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        style={{
                            margin: "0 1%",
                        }}
                        disabled={!canSaveArticle()}
                    >
                        Crear
                    </Button>
                </Footer>
            </Layout>
        </Space>
    );
}
