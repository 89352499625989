import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
    Navigate,
} from "react-router-dom";
import NewResourceAdminView from "./views/admin/newResourceAdminView";
import ResourcesUserView from "./views/user/resourcesUserView";
import SolutionUserView from "./views/user/solutionUserView";
import HomeUserView from "./views/user/home/homeUserView";
import LoginAdminView from "./views/admin/loginAdminView";
import HomeAdminView from "./views/admin/homeAdminView";
import CommunityView from "./views/user/communityView";
import TeamUserView from "./views/user/teamUserView";
import useToken from "./utils/useToken";
import "./App.css";

const PrivateRoutes = () => {
    const { token } = useToken();

    return token === undefined || token === "undefined" ? (
        <Navigate to="/admin/login" />
    ) : (
        <Outlet />
    );
};

function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" Component={HomeUserView}></Route>
                    <Route
                        path="/soluciones"
                        Component={SolutionUserView}
                    ></Route>
                    <Route path="/equipo" Component={TeamUserView}></Route>
                    <Route
                        path="/admin/login"
                        Component={LoginAdminView}
                    ></Route>
                    <Route element={<PrivateRoutes />}>
                        <Route
                            path="/admin/home"
                            Component={HomeAdminView}
                        ></Route>
                        <Route
                            path="/admin/new-resource"
                            Component={NewResourceAdminView}
                        ></Route>
                    </Route>
                    <Route
                        path="/recursos"
                        Component={ResourcesUserView}
                    ></Route>
                    <Route path="/comunidad" Component={CommunityView}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
