export function getText(htmlToProcess: string){
    var divContainer= document.createElement("div");
    divContainer.innerHTML = htmlToProcess;
    return divContainer.textContent || divContainer.innerText || "";
};

export function getDate(dateToProcess: string){
    const dateFormatted = new Date(dateToProcess);
    const dateToReturn = stylizeDate(dateFormatted.toLocaleString('es-mx', { day:'numeric', month: 'short', year: 'numeric'}));
    return dateToReturn;
}

function stylizeDate(dateToStylize: string){
    const dateToFormat = dateToStylize.split(" ");
    const monthStylized = dateToFormat[1].toUpperCase();
    return dateToFormat[0] + " " + monthStylized + " " + dateToFormat[2];
}