import { useState, useEffect } from "react";
import { Card, Divider } from 'antd';
import "./resourceCardStyles.scss";
import { getText } from '../../utils/utils';

const { Meta } = Card;

export default function ResourceCard({
    title,
    publishedDate,
    description,
    coverImage,
    author,
    resource,
    resourceType,
    setPdfUrl,
    setIsModalOpen,
    setSelectedResource,
    toggleHeaderLightMode,
    setToggleHeaderLightMode
}: any) {
    return (
        <Card
            cover={
                <img
                    alt="example"
                    src={coverImage}
                />
            }
            onClick={() => {
                if (resourceType === "Archivo") {
                    setPdfUrl(resource.archive_url);
                    setIsModalOpen(true);
                } else {
                    setSelectedResource(resource);
                    setToggleHeaderLightMode(!toggleHeaderLightMode);
                }
            }}
        >
            <div className="resource-published-date">
                {publishedDate ? publishedDate : "N/A"}
                </div>
            <Meta
                title={title ? title : 'Default Title'}
                description={description ? getText(description) : 'N/A'}
            />
            <Divider />
            <div className="resource-author">
                {author ? author : 'N/A'}
            </div>
        </Card>
    );
}