import { ReactComponent as Arrow } from "../../../assets/icons/arrow_icon.svg";
import { ReactComponent as Icon01 } from "../../../assets/icons/home_01.svg";
import { ReactComponent as Icon02 } from "../../../assets/icons/home_02.svg";
import { ReactComponent as Icon03 } from "../../../assets/icons/home_03.svg";
import { ReactComponent as Icon04 } from "../../../assets/icons/home_04.svg";
import { ReactComponent as Icon05 } from "../../../assets/icons/home_05.svg";
import { ReactComponent as Icon06 } from "../../../assets/icons/home_06.svg";
import { ReactComponent as Icon07 } from "../../../assets/icons/home_07.svg";
import ModalDemoForm from "../../../components/modalDemoForm/modalDemoForm";
import SisefarLayout from "../../../assets/images/home_sisefar_layout.png";
import HeaderUserComponent from "../../../components/headerUserComponent";
import FooterUserComponent from "../../../components/footerUserComponent";
import backgroundVideo from "../../../assets/videos/background_home.mp4";
import GradientOrb from "../../../assets/images/gradient_orb_home.png";
import MuguerzaLogo from "../../../assets/logo_muguerza.png";
import InformationCarrousel from "./informationCarrousel";
import useToken from "../../../utils/useToken";
import { useEffect, useState } from "react";
import Layout from "antd/es/layout/layout";
import { notification } from "antd";
import "./homeUserStyles.scss";
import "../../../styles.scss";

export default function HomeUserView() {
    const tokenController = useToken();

    const [currentQuoteIndex, setCurrentQuoteIndex] = useState<number>(0);
    const [email, setEmail] = useState<string>("");

    const [headerIsLightMode, setHeaderIslightMode] = useState<boolean>(false);

    const [impactos, setImpactos] = useState([]);

    useEffect(() => {
        fetch("https://back-landing-sisefar.azurewebsites.net/api/impact/all", {
            headers: {
                Authorization: `Bearer ${tokenController.getToken()}`,
            },
        })
            .then((res) => res.json())
            .then((body) => setImpactos(body));
    }, []);

    const detectCurrentSection = () => {
        const scrollContainer = document.getElementById(
            "scroll-container-home"
        );
        const scrollTop = scrollContainer?.scrollTop || document.body.scrollTop;

        const windowHeight = window.innerHeight;

        const windowMiddle = scrollTop + windowHeight / 2;

        const sections = Array.from(document.getElementsByClassName("section"));

        sections.forEach((section: any, index: number) => {
            const sectionTop = section.offsetTop;
            const sectionBottom = sectionTop + section.offsetHeight;

            if (windowMiddle >= sectionTop && windowMiddle <= sectionBottom) {
                if ([0, 3].includes(index)) {
                    setHeaderIslightMode(false);
                } else {
                    setHeaderIslightMode(true);
                }
            }
        });
    };

    useEffect(() => {
        document
            .getElementById("scroll-container-home")
            ?.addEventListener("scroll", detectCurrentSection);
    }, []);

    const scrollTo = (nameElement: string) => {
        document
            .getElementById(nameElement)
            ?.scrollIntoView({ behavior: "smooth" });
    };

    const previousQuoteIndex = (): void => {
        setCurrentQuoteIndex(
            currentQuoteIndex === 0
                ? impactos.length - 1
                : currentQuoteIndex - 1
        );
    };

    const nextQuoteIndex = (): void => {
        setCurrentQuoteIndex(
            currentQuoteIndex < impactos.length - 1 ? currentQuoteIndex + 1 : 0
        );
    };

    const sendContact = (): void => {
        if (email !== "") {
            fetch(
                "https://back-landing-sisefar.azurewebsites.net/api/contact/create",
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${tokenController.getToken()}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name: "",
                        phone_number: "",
                        email,
                    }),
                }
            )
                .then(() => {
                    notification.success({
                        message: "Hemos recibido tu correo.",
                    });
                    setEmail("");
                })
                .catch(() => {
                    notification.error({
                        message: "Ha ocurrido un error. Inténtalo de nuevo.",
                    });
                });
        } else {
            notification.warning({ message: "Por favor, ingresa tu correo." });
        }
    };

    const openModalDemoForm = () => {
        const modalDemo = document.getElementById("modal-demo-form");
        if (modalDemo === null) return;
        modalDemo.style.display = "flex";
    };

    return (
        <div id="scroll-container-home" className="scroll-container">
            <HeaderUserComponent
                lightMode={headerIsLightMode}
            ></HeaderUserComponent>
            <div id="scroller">
                <Layout className="gradient section">
                    <InformationCarrousel />
                    <video autoPlay loop muted key={"video"}>
                        <source src={backgroundVideo} type="video/mp4"></source>
                    </video>
                </Layout>
                <div id="first-slide" className="section">
                    <div className="section-container">
                        <h2>
                            Refuerza la{" "}
                            <span className="animation-gradient-text">
                                seguridad del paciente
                            </span>
                        </h2>
                        <span>
                            SISEFARPRO proporciona funcionalidades que
                            contribuyen a tomar decisiones informadas, lo que se
                            traduce en una atención de mayor calidad al
                            paciente.
                        </span>
                        <button
                            onClick={() => scrollTo("second-slide")}
                            className="splash-button left-icon left-icon-search"
                        >
                            Conoce más
                        </button>
                    </div>
                    <div className="grid-container">
                        <img src={GradientOrb} />
                        <div className="grid-row">
                            <div className="container margin-space">
                                <div className="home-icon">
                                    <Icon01 />
                                </div>
                                <h5>
                                    Garantiza seguridad farmacoepidemiológica
                                </h5>
                                <span>
                                    SISEFARPRO es sinónimo de prevención: menos
                                    infecciones asociadas a medicamentos y una
                                    gestión epidemiológica avanzada que optimiza
                                    recursos y seguridad hospitalaria. Menos
                                    hospitalizaciones por medicación inadecuada
                                    y más decisiones clínicas informadas.
                                </span>
                            </div>
                            <div className="container">
                                <div className="home-icon">
                                    <Icon02 />
                                </div>
                                <h5>Optimiza el uso seguro de medicamentos</h5>
                                <span>
                                    SISEFARPRO redefine la seguridad
                                    farmacológica en hospitales al reducir
                                    errores de medicación, monitorizar efectos
                                    adversos y prevenir hospitalizaciones
                                    relacionadas con medicamentos inapropiados.
                                </span>
                            </div>
                        </div>
                        <div className="container">
                            <div className="home-icon">
                                <Icon03 />
                            </div>
                            <h5 className="large-text">
                                Asegura el proceso de <br></br>idoneidad de la
                                prescripción
                            </h5>
                            <span className="large-text">
                                Asegura la idoneidad de la prescripción con
                                SISEFARPRO, que brinda apoyo a farmacéuticos en
                                el seguimiento y monitorización
                                farmacoterapéutica desde la selección hasta la
                                dispensación.
                            </span>
                        </div>
                    </div>
                </div>
                <div id="second-slide" className="section">
                    <div className="grid-container left-grid">
                        <img src={GradientOrb} />
                        <div className="grid-row">
                            <div className="container margin-space">
                                <div className="home-icon">
                                    <Icon04 />
                                </div>
                                <h5>Respalda decisiones con farmaeconómicas</h5>
                                <span>
                                    SISEFARPRO garantiza una farmacoterapia más
                                    segura y adecuada, impactando positivamente
                                    en la economía del hospital mediante la
                                    reducción de errores y el aprovechamiento
                                    eficiente de recursos.
                                </span>
                            </div>
                            <div className="container">
                                <div className="home-icon">
                                    <Icon05 />
                                </div>
                                <h5>
                                    Implementa, monitoriza y optimiza tu plan
                                    PROA
                                </h5>
                                <span>
                                    SISEFARPRO potencia los programas PROA en
                                    hospitales con módulos clínicos como
                                    duración de tratamiento antibiótico y
                                    descalonamiento de antibióticos. Monitoriza
                                    y optimiza la terapia secuencial y ajuste de
                                    dosis por función renal para una
                                    farmacoterapia antimicrobiana precisa.
                                </span>
                            </div>
                        </div>
                        <div className="grid-row">
                            <div className="container margin-space">
                                <div className="home-icon">
                                    <Icon06 />
                                </div>
                                <h5>
                                    Eficientiza la administración de inventarios
                                </h5>
                                <span>
                                    Impulsa una gestión de inventarios más
                                    efectiva en hospitales, permitiendo un mejor
                                    control, seguimiento y planificación de los
                                    medicamentos, lo que resulta en asegurar que
                                    los medicamentos estén disponibles cuando
                                    más se necesitan y una reducción de
                                    desperdicios.
                                </span>
                            </div>
                            <div className="container">
                                <div className="home-icon">
                                    <Icon07 />
                                </div>
                                <h5>
                                    Genera las mejores estrategias de mejora
                                </h5>
                                <span>
                                    Al contar con estadísticas clínicas y
                                    administrativas precisas, SISEFARPRO
                                    habilita a los hospitales para evaluar el
                                    impacto de sus intervenciones y tomar
                                    decisiones basadas en evidencia para la
                                    mejora continua.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="section-container right-container">
                        <h2>
                            Agiliza procesos{" "}
                            <span className="animation-gradient-text">
                                administrativos
                            </span>
                        </h2>
                        <span>
                            SISEFARPRO es un sistema de gestión y seguimiento
                            farmacológico que tiene un impacto significativo
                            tanto en el ámbito clínico como administrativo.
                        </span>
                        <button
                            onClick={() => scrollTo("test-platform-section")}
                            className="splash-button left-icon left-icon-search"
                        >
                            Conoce más
                        </button>
                    </div>
                </div>
                <div id="test-platform-section" className="section">
                    <div id="quotes">
                        <video autoPlay loop muted key={"video"}>
                            <source
                                src={backgroundVideo}
                                type="video/mp4"
                            ></source>
                        </video>
                        <Arrow
                            onClick={previousQuoteIndex}
                            className="quotes__arrow quotes__arrow--left"
                        />

                        <div className="quote-information">
                            <h2>Nuestro impacto</h2>
                            {impactos.length === 0 && (
                                <>
                                    <span>
                                        "Este desarrollo nos ayudó a xx%
                                        porcentaje de nuestro hospital"
                                    </span>
                                    <div className="quote-author">
                                        <img src={MuguerzaLogo}></img>
                                        <div>
                                            <h5>Dr. John Doe</h5>
                                            <span>
                                                Director de algo de CHRISTUS
                                                Muguerza
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}

                            {impactos.length !== 0 && (
                                <>
                                    <span>
                                        {
                                            impactos[currentQuoteIndex][
                                                "description"
                                            ]
                                        }
                                    </span>
                                    <div className="quote-author">
                                        <img src={MuguerzaLogo}></img>
                                        <div>
                                            <h5>
                                                {
                                                    impactos[currentQuoteIndex][
                                                        "publisher_name"
                                                    ]
                                                }
                                            </h5>
                                            <span>
                                                {
                                                    impactos[currentQuoteIndex][
                                                        "publisher_role"
                                                    ]
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <Arrow
                            onClick={nextQuoteIndex}
                            className="quotes__arrow quotes__arrow--right"
                        />
                    </div>
                    <div id="contact">
                        <div>
                            <h4>¿Te interesa probar nuestra plataforma?</h4>
                            <span>
                                Solicita un demo{" "}
                                <a onClick={openModalDemoForm}>aquí</a> o deja
                                tu correo electrónico y nos pondremos en
                                contacto contigo.
                            </span>
                            <div id="community-contact">
                                <span>Correo electrónico</span>
                                <div>
                                    <input
                                        type="mail"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    ></input>{" "}
                                    <button
                                        className="splash-button left-icon left-icon-send"
                                        onClick={sendContact}
                                    >
                                        Enviar
                                    </button>
                                </div>
                            </div>
                        </div>

                        <img src={SisefarLayout}></img>
                    </div>
                    <div id="white-space"></div>
                </div>
            </div>
            <FooterUserComponent></FooterUserComponent>
            <ModalDemoForm />
        </div>
    );
}
