import backgroundVideo from "../../assets/videos/background_community.mp4";
import HeaderUserComponent from "../../components/headerUserComponent";
import FooterUserComponent from "../../components/footerUserComponent";
import "./communityStyles.css";
import { Layout, notification } from "antd";
import "../../styles.scss";
import { useState } from "react";
import useToken from "../../utils/useToken";

export default function CommunityView() {
    const tokenController = useToken();

    const [email, setEmail] = useState<string>("");

    const sendContact = (): void => {
        if (email !== "") {
            fetch(
                "https://back-landing-sisefar.azurewebsites.net/api/contact/create",
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${tokenController.getToken()}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name: "",
                        phone_number: "",
                        email,
                    }),
                }
            )
                .then(() => {
                    notification.success({
                        message: "Hemos recibido tu correo.",
                    });
                    setEmail("");
                })
                .catch(() => {
                    notification.error({
                        message: "Ha ocurrido un error. Inténtalo de nuevo.",
                    });
                });
        } else {
            notification.warning({ message: "Por favor, ingresa tu correo." });
        }
    };

    return (
        <Layout className="gradient">
            <HeaderUserComponent lightMode={true}></HeaderUserComponent>
            <div id="community-body">
                <div id="community-center">
                    <div id="community-container" className="glass-background">
                        <div id="community-information">
                            <h5>Muy pronto</h5>
                            <h1>
                                Seguimos construyendo nuestra{" "}
                                <span className="animation-gradient-text">
                                    comunidad
                                </span>
                                .
                            </h1>

                            <span>
                                Por el momento, deja tus datos aquí y nos
                                pondremos en contacto contigo.
                            </span>
                        </div>
                        <div id="community-contact">
                            <div style={{ width: "32rem" }}>
                                <span>Correo electrónico</span>
                            </div>
                            <div>
                                <input
                                    type="mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                ></input>{" "}
                                <button
                                    className="splash-button left-icon left-icon-send"
                                    onClick={sendContact}
                                >
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterUserComponent />
            </div>
            <video autoPlay loop muted>
                <source src={backgroundVideo} type="video/mp4"></source>
            </video>
        </Layout>
    );
}
