import logoTraditional from "../assets/images/sisefar_logo_traditional.png";
import { useNavigate, useLocation } from "react-router-dom";
import ModalDemoForm from "./modalDemoForm/modalDemoForm";
import logo from "../assets/images/sisefar_logo_01.png";
import { Header } from "antd/es/layout/layout";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import "./header.scss";
import "../styles.scss";

export default function HeaderUserComponent(props: { lightMode: boolean }) {
    const navigate = useNavigate();

    const location = useLocation();

    const items = [
        {
            label: "Soluciones",
            key: "soluciones",
        },
        {
            label: "Equipo",
            key: "equipo",
        },
        {
            label: "Recursos",
            key: "recursos",
        },
        {
            label: "Comunidad",
            key: "comunidad",
        },
    ];

    const isCurrentRoute = (route: string): boolean => {
        return route === location.pathname;
    };

    const openModalDemoForm = () => {
        const modalDemo = document.getElementById("modal-demo-form");
        if (modalDemo === null) return;
        modalDemo.style.display = "flex";
    };

    return (
        <Header
            id="header-container"
            style={{
                background: props.lightMode
                    ? "linear-gradient(180deg, #FFFF 55%, rgba(255, 255, 255, 0.00) 100%)"
                    : undefined,
                height: props.lightMode ? "15vh" : undefined,
            }}
        >
            <div id="main-container">
                <div id="logo-container">
                    <motion.img
                        whileHover={{
                            width: "45%",
                        }}
                        onClick={(e) => navigate("/")}
                        style={{ width: "40%", cursor: "pointer" }}
                        src={props.lightMode ? logoTraditional : logo}
                    ></motion.img>
                </div>
                <div id="menu-container">
                    <div id="menu-style">
                        {items.map((item) => (
                            <div key={uuidv4()}>
                                <a rel="noreferrer" href={`/${item.key}`}>
                                    <h4
                                        className={`span-hover menu-options ${
                                            props.lightMode
                                                ? "light-options light-span-hover"
                                                : undefined
                                        } ${
                                            isCurrentRoute(`/${item.key}`)
                                                ? props.lightMode
                                                    ? "light-span-hover--active"
                                                    : "span-hover--active"
                                                : undefined
                                        }`}
                                    >
                                        {item.label}
                                    </h4>
                                </a>
                            </div>
                        ))}
                    </div>

                    <button
                        id="menu-button-demo"
                        className={`splash-button left-icon-calendar left-icon ${
                            props.lightMode ? "light-button" : ""
                        }`}
                        onClick={openModalDemoForm}
                    >
                        Solicita un demo
                    </button>
                    <ModalDemoForm />
                </div>
            </div>
        </Header>
    );
}
