import { ReactComponent as SeeMoreIcon } from "../../../assets/icons/home_see_more.svg";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow_icon.svg";
import logo from "../../../assets/images/sisefar_logo_monocromatico.png";
import ModalDemoForm from "../../../components/modalDemoForm/modalDemoForm";
import TabImage01 from "../../../assets/images/home_tab_01.jpg";
import TabImage02 from "../../../assets/images/home_tab_02.jpg";
import TabImage03 from "../../../assets/images/home_tab_03.jpg";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import "./informationCarrousel.scss";
import "../../../styles.scss";
import { useNavigate } from "react-router-dom";

const toggleStep = {
    open: {
        flexGrow: 4,
        marginInLine: "0.5vw",
        opacity: 1,
    },
    closed: {
        opacity: 0.3,
        marginInLine: "2px",
    },
};

export default function InformationCarrousel() {
    const navigate = useNavigate();

    const medicines = [
        "Linezolid",
        "Fluconazol",
        "Ampicilina",
        "Metronidazol",
        "Clindamicina",
        "Ciprofloxacino",
    ];

    const [selectedMedicines, setSelectedMedicines] = useState<number>(1);
    const [currentRecentPatients, setCurrentRecentPatients] =
        useState<number>(1);

    const [selectedButton, setSelectedButton] = useState<number>(0);

    const [index, setIndex] = useState<number>(0);

    const openModalDemoForm = () => {
        const modalDemo = document.getElementById("modal-demo-form");
        if (modalDemo === null) return;
        modalDemo.style.display = "flex";
    };

    let images = [TabImage01, TabImage02, TabImage03];

    let titles = [
        "Excelencia en \n gestión farmacológica",
        "Seguridad del \n paciente mejorada",
        "Optimización del \ntrabajo farmacéutico",
    ];

    let bodies = [
        "Nuestra plataforma de validación farmacéutica elimina errores y proporciona una herramienta integral de gestión y seguimiento. Olvídate de procesos manuales y errores en la gestión de medicamentos.",
        "SISEFARPRO es un completo sistema de gestión y seguimiento farmacológico que garantiza la idoneidad de prescripciones contribuyendo a la prevención de infecciones y facilitando la gestión de la resistencia antimicrobiana.",
        "SISEFARPRO optimiza la labor del químico \nfarmacéutico al simplificar la gestión de \ninformación del paciente, reducir transcripciones \nmanuales y agilizar procesos administrativos.",
    ];

    const previousIndex = (): void => {
        setIndex(index === 0 ? titles.length - 1 : index - 1);
    };

    const nextIndex = (): void => {
        setIndex(index < titles.length - 1 ? index + 1 : 0);
    };

    const stepIsActive = (currentIndex: number): boolean => {
        return index === currentIndex;
    };

    const currentBoldTitle = (): string => {
        switch (index) {
            case 0:
                return "farmacológica";
            case 1:
                return "paciente";

            default:
                return "farmacéutico";
        }
    };

    const updateTitleBold = () => {
        const title = document.getElementById(titles[index]);
        const titleText = title?.innerHTML;

        let regex = new RegExp("(" + currentBoldTitle() + ")", "i");
        let boldText = titleText?.replace(regex, "<b>$1</b>");

        if (title !== undefined && boldText !== undefined) {
            title!.innerHTML = boldText;
        }
    };

    useEffect(() => {
        setCurrentRecentPatients(1);
        setSelectedMedicines(1);
        updateTitleBold();
    }, [index]);

    const buildButtons = (): JSX.Element[] => {
        switch (index) {
            case 0:
                return [
                    <button
                        onClick={() =>
                            setSelectedMedicines(selectedMedicines + 1)
                        }
                        className="slide-active-button"
                    >
                        Agregar
                    </button>,
                ];
            case 1:
                return [
                    <button
                        onClick={() =>
                            setCurrentRecentPatients(currentRecentPatients + 1)
                        }
                        className="slide-active-button large-button "
                    >
                        Nueva admisión +
                    </button>,
                ];

            default:
                return [
                    <button
                        onClick={() => setSelectedButton(0)}
                        className={`large-button ${
                            selectedButton === 0
                                ? "slide-active-button"
                                : "slide-normal-button"
                        }  `}
                    >
                        Información clínica
                    </button>,
                    <button
                        onClick={() => setSelectedButton(1)}
                        className={`large-button ${
                            selectedButton === 1
                                ? "slide-active-button"
                                : "slide-normal-button"
                        }`}
                    >
                        Indicaciones médicas
                    </button>,
                    <button
                        onClick={() => setSelectedButton(2)}
                        className={`large-button ${
                            selectedButton === 2
                                ? "slide-active-button"
                                : "slide-normal-button"
                        }`}
                    >
                        Exámenes de laboratorio
                    </button>,
                ];
        }
    };

    const buildTitleClinicInformation = (): string[] => {
        switch (selectedButton) {
            case 0:
                return ["Diagnóstico del paciente"];
            case 1:
                return [
                    "Indicaciones médicas de prescripción",
                    "Medicamentos actuales",
                ];
            default:
                return ["Exámenes de laboratorio"];
        }
    };

    const buildSlideTitles = () => {
        switch (index) {
            case 0:
                return "Medicamentos actuales";

            case 1:
                return "Pacientes recientes";

            default:
                return buildTitleClinicInformation()[0];
        }
    };

    const buildMedicineSlide = (): JSX.Element[] => {
        return medicines.slice(0, selectedMedicines).map((medicine: string) => {
            return (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="row"
                    key={medicine}
                >
                    <div className="row-text col-3">{medicine}</div>
                    <div className="skeleton col-3"></div>
                    <div className="skeleton col-2"></div>
                    <div className="skeleton col-2"></div>
                    <div className="skeleton col-1"></div>
                </motion.div>
            );
        });
    };

    const buildRecentPatientsSlide = (): JSX.Element[] => {
        const recentPatients: JSX.Element[] = [];

        for (let index = 0; index < currentRecentPatients; index++) {
            recentPatients.push(
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="row"
                    key={index}
                >
                    <div className="skeleton col-3"></div>
                    <div className="skeleton col-3"></div>
                    <div className="skeleton col-2"></div>
                    <div className="skeleton col-2"></div>
                    <div className="skeleton col-1"></div>
                </motion.div>
            );
        }

        return recentPatients.reverse();
    };

    const buildClinicInformationSlide = () => {
        switch (selectedButton) {
            case 0:
                return [
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="row"
                    >
                        <div className="row-text-small col-3">
                            Diagnóstico asociado a infección
                        </div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-1"></div>
                        <div className="skeleton col-1"></div>
                    </motion.div>,
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="row"
                    >
                        <div className="row-text-small col-3">
                            Diagnóstico de ingreso no infeccioso
                        </div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-1"></div>
                        <div className="skeleton col-1"></div>
                    </motion.div>,
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="row"
                    >
                        <div className="row-text-small col-3">
                            Comorbilidades
                        </div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-1"></div>
                        <div className="skeleton col-1"></div>
                    </motion.div>,
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="row"
                    >
                        <div className="row-text-small col-3">Alergias</div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-1"></div>
                        <div className="skeleton col-1"></div>
                    </motion.div>,
                ];
            case 1:
                return [
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="row"
                    >
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-3"></div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-1"></div>
                        <div className="skeleton col-1"></div>
                    </motion.div>,
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="row"
                    >
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-3"></div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-1"></div>
                        <div className="skeleton col-1"></div>
                    </motion.div>,
                    <h4>{buildTitleClinicInformation()[1]}</h4>,
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="row"
                    >
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-3"></div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-1"></div>
                        <div className="skeleton col-1"></div>
                    </motion.div>,
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="row"
                    >
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-3"></div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-1"></div>
                        <div className="skeleton col-1"></div>
                    </motion.div>,
                ];

            default:
                return [
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="row"
                    >
                        <div className="row-text-small col-2">Creatinina</div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-1"></div>
                        <div className="skeleton col-1"></div>
                    </motion.div>,
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="row"
                    >
                        <div className="row-text-small col-2">
                            Procalcitonina (PCT)
                        </div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-1"></div>
                        <div className="skeleton col-1"></div>
                    </motion.div>,
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="row"
                    >
                        <div className="row-text-small col-2">Cortisol</div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-1"></div>
                        <div className="skeleton col-1"></div>
                    </motion.div>,
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="row"
                    >
                        <div className="row-text-small col-2">Troponina I</div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-2"></div>
                        <div className="skeleton col-1"></div>
                        <div className="skeleton col-1"></div>
                    </motion.div>,
                ];
        }
    };

    const buildSlidesBody = () => {
        switch (index) {
            case 0:
                return buildMedicineSlide();

            case 1:
                return buildRecentPatientsSlide();

            default:
                return buildClinicInformationSlide();
        }
    };

    return (
        <div id="carrousel-container">
            <Arrow
                style={{ zIndex: "3" }}
                onClick={previousIndex}
                cursor={"pointer"}
                transform="rotate(180)"
            />
            <div id="slides-container">
                <div id="information-container">
                    <div id="progress-line">
                        <motion.div
                            animate={stepIsActive(0) ? "open" : "closed"}
                            variants={toggleStep}
                            className={"dot"}
                        ></motion.div>
                        <motion.div
                            animate={stepIsActive(1) ? "open" : "closed"}
                            variants={toggleStep}
                            className={"dot"}
                        ></motion.div>
                        <motion.div
                            animate={stepIsActive(2) ? "open" : "closed"}
                            variants={toggleStep}
                            className={"dot"}
                        ></motion.div>
                    </div>
                    <AnimatePresence>
                        <motion.h2
                            transition={{ duration: 0.5 }}
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1,
                                zIndex: 2,
                            }}
                            exit={{
                                opacity: 0,
                                position: "absolute",
                                zIndex: 1,
                            }}
                            id={titles[index]}
                            className="information-title"
                            key={index}
                        >
                            {titles[index]}
                        </motion.h2>
                    </AnimatePresence>
                    <div id="body">
                        <AnimatePresence>
                            <motion.span
                                transition={{ duration: 0.5 }}
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    zIndex: 2,
                                }}
                                exit={{
                                    opacity: 0,
                                    position: "absolute",
                                    zIndex: 1,
                                }}
                                id="information-body"
                                key={index}
                            >
                                {bodies[index]}
                            </motion.span>
                        </AnimatePresence>

                        <div id="information-buttons">
                            <button
                                id="menu-button-demo"
                                className={
                                    "splash-button left-icon-calendar left-icon"
                                }
                                onClick={openModalDemoForm}
                            >
                                Solicita un demo
                            </button>
                            <button
                                onClick={() => navigate("/comunidad")}
                                id="community-button"
                            >
                                Únete a la comunidad
                            </button>
                        </div>
                    </div>
                </div>
                <div id="slide-container">
                    <div id="tab-container">
                        <div id="title-container">
                            <img alt="" src={logo}></img>
                        </div>
                        <AnimatePresence>
                            <motion.h4
                                transition={{ duration: 0.5 }}
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    zIndex: 2,
                                }}
                                exit={{
                                    opacity: 0,
                                    position: "absolute",
                                    zIndex: 1,
                                }}
                                key={buildSlideTitles()}
                            >
                                {buildSlideTitles()}
                            </motion.h4>
                        </AnimatePresence>

                        {buildSlidesBody()}
                    </div>
                    <div id="tab-image">
                        <AnimatePresence>
                            <motion.img
                                alt=""
                                transition={{ duration: 0.5 }}
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    zIndex: 2,
                                }}
                                exit={{
                                    opacity: 0,
                                    position: "absolute",
                                    zIndex: 1,
                                }}
                                key={images[index]}
                                src={images[index]}
                            ></motion.img>
                        </AnimatePresence>
                    </div>

                    <div id="slide-buttons">{buildButtons()}</div>

                    <div id="dot-01" className="dark-dot"></div>
                    <div id="dot-02" className="light-dot"></div>
                    <div id="dot-03" className="dark-dot"></div>
                    <div id="dot-04" className="light-dot"></div>
                    <div id="dot-05" className="dark-dot"></div>
                    <div id="dot-06" className="light-dot"></div>
                </div>
            </div>
            <Arrow
                style={{ zIndex: "3" }}
                onClick={nextIndex}
                cursor={"pointer"}
            />
            <div className="see-more-container">
                <div
                    className="see-more"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        document
                            .getElementById("first-slide")
                            ?.scrollIntoView({ behavior: "smooth" });
                    }}
                >
                    <span>Descubre más</span>
                    <motion.div
                        animate={{
                            y: [-12, -20, -12],
                            opacity: [0.9, 0.8, 0.9],
                        }}
                        transition={{
                            ease: "easeOut",
                            repeat: Infinity,
                            yoyo: Infinity,
                            repeatDelay: 1,
                            duration: 0.5,
                        }}
                    >
                        <SeeMoreIcon />
                    </motion.div>
                </div>
            </div>
        </div>
    );
}
