import backgroundVideo from "../../assets/videos/background_home.mp4";
import "./modalContactFormStyles.scss";
import { useState } from "react";
import useToken from "../../utils/useToken";
import { Input, notification } from "antd";
import "../../styles.scss";

export default function ModalContactForm({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: Function;
}) {
    const tokenController = useToken();
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [processingContact, setProcessingContact] = useState<boolean>(false);

    const clearForm = (): void => {
        setName("");
        setPhone("");
        setEmail("");
    };

    const canContact = (): boolean => {
        if (name !== "" && email !== "" && phone !== "") return true;
        else return false;
    };

    const createContact = () => {
        if (canContact() && !processingContact) {
            setProcessingContact(true);
            fetch(
                "https://back-landing-sisefar.azurewebsites.net/api/contact/create",
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${tokenController.getToken()}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name,
                        email,
                        phone_number: phone,
                    }),
                }
            )
                .then((res) => res.json())
                .then(() => {
                    notification.success({
                        message: "Contacto creado exitosamente!!",
                    });
                    setProcessingContact(false);
                    closeModal();
                });
        }
    };

    const closeModal = (): void => {
        clearForm();
        setOpen();
    };

    return (
        <div
            className="custom-modal"
            style={{
                display: `${open ? "flex" : "none"}`,
            }}
        >
            <div id="modal-contact-body">
                <span onClick={closeModal} className="material-symbols-rounded">
                    close
                </span>
                <div id="modal-contact-video">
                    <video autoPlay loop muted>
                        <source src={backgroundVideo} type="video/mp4"></source>
                    </video>
                </div>
                <div id="modal-contact-inputs">
                    <h3>¿Quieres saber más</h3>
                    <span>
                        ¡Deja tus datos aquí y nos pondremos en contacto!
                    </span>

                    <div>
                        <label>Nombre(s)</label>
                        <Input
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                        ></Input>
                        <label>Correo electrónico</label>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                        ></Input>
                        <label>Teléfono</label>
                        <Input
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.currentTarget.value)}
                        ></Input>
                    </div>

                    <button
                        style={{
                            background: `${
                                canContact() ? "#329B99" : "#b7dfd9"
                            }`,
                            cursor: `${canContact() ? "pointer" : "default"}`,
                        }}
                        onClick={createContact}
                    >
                        {" "}
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    );
}
