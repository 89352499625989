import { ReactComponent as SeeMoreIcon } from "../../assets/icons/home_see_more.svg";
import { ReactComponent as ArrowDownward } from "../../assets/icons/arrow_downward.svg";
import Image01 from "../../assets/images/solutions_sisefar_layout_01.png";
import Image02 from "../../assets/images/solutions_sisefar_layout_02.png";
import Image03 from "../../assets/images/solutions_sisefar_layout_03.png";
import Image04 from "../../assets/images/solutions_sisefar_layout_04.png";
import HeaderUserComponent from "../../components/headerUserComponent";
import FooterUserComponent from "../../components/footerUserComponent";
import backgroundVideo from "../../assets/videos/background_home.mp4";
import Module01 from "../../assets/images/solutions_module_01.png";
import Module02 from "../../assets/images/solutions_module_02.png";
import Module03 from "../../assets/images/solutions_module_03.png";
import Module04 from "../../assets/images/solutions_module_04.png";
import Module05 from "../../assets/images/solutions_module_05.png";
import Module06 from "../../assets/images/solutions_module_06.png";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./solutionUserView.scss";
import { Layout, Checkbox } from "antd";

export default function SolutionUserView() {
    const [headerIsLightMode, setHeaderIslightMode] = useState<boolean>(false);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [currentModuleIndex, setCurrentModuleIndex] = useState<number>(0);

    let descriptions = [
        "Realiza el cambio de formulación de un fármaco para pasar de la administración endovenosa a la vía enteral (vía oral o por sonda nasogástrica), reduciendo el riesgo de complicaciones, ya sea asociadas a la punción o desarrollo de infecciones, además de reducir los costos asociados al tratamiento.",
        "Ajusta la dosis antibiótica por aclaramiento renal, reduciendo el riesgo de toxicidad renal por los antibióticos.",
        "Realiza el cambio de un antibiótico de amplio espectro por otro de espectro reducido, según el antibiograma. Focalizado en aquellos microrganismos con alto potencial de multiresistencia.",
        "Evalúa la necesidad de indicación y dosis de anticoagulante para la prevención de eventos tromboembólicos (ETE) como Tromboembolismo pulmonar o Trombosis venosa profunda.",
        "Evalúa la necesidad de indicación y dosis de inhibidores de secreción ácida para la prevención de úlceras gástricas por estrés, reduciendo costos asociados al uso innecesario de inhibidores de la secreción ácida.",
        "Evalúa el consumo de antibióticos por unidad de paciente e institucional, reduciendo el consumo y costo asociado al uso de antibióticos en las instituciones de salud.",
    ];

    const images: string[] = [Image01, Image02, Image03, Image04];
    const modules: string[] = [
        Module01,
        Module02,
        Module03,
        Module04,
        Module05,
        Module06,
    ];

    const pills = [
        ["Seguimiento de pacientes", "#329b99"],
        ["Información clínica del paciente", "#226263"],
        ["Seguimiento de medicamentos actuales", "#000336"],
        ["Interacción con módulos clínicos", "#226263"],
    ];

    const checkboxFirstModal = [
        "Abscesos intracraneales",
        "Artritis séptica",
        "Bacteremia por Staphylococcus aureus",
        "Diarrea",
        "Endocarditis/mediastinitis",
        "Hemorragia gastrointestinal",
        "Íleo",
        "Implantes o prótesis infectados",
        "Infección necrosante de tejidos blandos",
        "Infección profunda (ej. absceso/empiema)",
    ];

    const checkboxSecondModal = [
        "Cáncer activo*",
        "Edad ≥ 70 años",
        "Enfermedad trombolítica previa (ETE) (excluida la trombosis venosa superficial)",
        "IAM o accidente cerebrovascular isquémico",
        "Infección aguda y/o enfermedad reumatológica",
        "Insuficiencia respiratoria y/o insuficiencia cardíaca",
        "Movilidad reducida**",
        "Obesidad (IMC ≥ 30)",
        "Terapia hormonal (estrógenos levotiroxina,antineoplástico)",
        "Traumatismo y/o cirugía reciente (≤1 mes)",
    ];

    const checkboxThirdModal = [
        "Dispepsia",
        "Esofagitis y esófago de Barrett",
        "Insuficiencia pancreática exocrina",
        "Reflujo gastroesofágico",
        "Sangrado en el tracto gastrointestinal activo (GIT) (p. Ej.,Hemorragia gastrointestinal superior o UGIH)",
        "Síndrome de gastrosecretor (síndrome de Zollinger Ellison)",
        "Tratamiento de Helicobacter pylori",
        "Úlcera gastro-duodenal",
    ];

    const nextIndex = (): void => {
        setCurrentImageIndex(
            currentImageIndex < images.length - 1 ? currentImageIndex + 1 : 0
        );
    };

    useEffect(() => {
        const img = document.getElementById("layout-sisefar-image");

        img?.addEventListener("mousemove", showTooltip);
        img?.addEventListener("mouseleave", hideTooltip);
    }, []);

    const showTooltip = (event: MouseEvent): void => {
        const tooltip = document.getElementById("follow-pill");

        if (tooltip === null) return;
        tooltip.style.visibility = "visible";

        tooltip.style.top =
            window.innerWidth !== 1280
                ? event.clientY - 165 + "px"
                : event.clientY - 120 + "px";
        tooltip.style.left = event.clientX + 6 + "px";
    };

    const hideTooltip = (): void => {
        const tooltip = document.getElementById("follow-pill");

        if (tooltip === null) return;
        tooltip.style.visibility = "hidden";
    };

    // Effect for second slide
    useEffect(() => {
        const secondSlide = document.getElementById("second-slide");

        if (secondSlide === null) return;

        secondSlide.addEventListener("mousemove", (e) => {
            secondSlide.style.background =
                "radial-gradient( circle at " +
                e.clientX +
                "px " +
                e.clientY +
                "px, " +
                "rgb(75, 182, 178, 1) 0%, " +
                "rgb(0, 3, 56) 50% )";
        });
    }, []);

    const moduleIsSelected = (index: number): boolean => {
        return index === currentModuleIndex;
    };

    // Function for update header mode
    const detectCurrentSection = () => {
        const scrollContainer = document.getElementById(
            "scroll-container-solutions"
        );
        const scrollTop = scrollContainer?.scrollTop || document.body.scrollTop;

        const windowHeight = window.innerHeight;

        const windowMiddle = scrollTop + windowHeight / 2;

        const sections = Array.from(document.getElementsByClassName("section"));
        console.log();

        sections.forEach((section: any, index: number) => {
            const sectionTop = section.offsetTop;
            const sectionBottom = sectionTop + section.offsetHeight;

            if (windowMiddle >= sectionTop && windowMiddle <= sectionBottom) {
                if (index === 2) {
                    setHeaderIslightMode(true);
                } else {
                    setHeaderIslightMode(false);
                }
            }
        });
    };

    useEffect(() => {
        document
            .getElementById("scroll-container-solutions")
            ?.addEventListener("scroll", detectCurrentSection);
    }, []);

    return (
        <div id="scroll-container-solutions" className="scroll-container">
            <HeaderUserComponent
                lightMode={headerIsLightMode}
            ></HeaderUserComponent>
            <div id="scroller">
                <Layout className="gradient section">
                    <div className="solutions-first-slide">
                        <h4
                            id="first-slide-title"
                            className="solutions-first-slide__title"
                        >
                            El futuro de la <span>farmacovigilancia</span>{" "}
                            inicia aquí <ArrowDownward />
                        </h4>

                        <img
                            onClick={nextIndex}
                            id="layout-sisefar-image"
                            className="solutions-first-slide__image"
                            src={images[currentImageIndex]}
                        ></img>

                        <div className="solutions-first-slide__white-space"></div>
                        <div
                            id="follow-pill"
                            className="solutions-first-slide__follow-pill"
                            style={{
                                background: pills[currentImageIndex][1],
                            }}
                        >
                            {pills[currentImageIndex][0]}
                        </div>
                    </div>
                    <video autoPlay loop muted key={"video"}>
                        <source src={backgroundVideo} type="video/mp4"></source>
                    </video>
                </Layout>
                <div
                    id="second-slide"
                    className="section solutions-second-slide"
                >
                    <h1>
                        Gestión farmacológica completa para una atención de
                        calidad, reduciendo errores, optimizando recursos y
                        agilizando procesos administrativos.
                    </h1>
                    <div className="see-more-container">
                        <div
                            className="see-more"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                document
                                    .getElementById("third-slide")
                                    ?.scrollIntoView({ behavior: "smooth" });
                            }}
                        >
                            <motion.div
                                animate={{
                                    y: [-12, -20, -12],
                                    opacity: [0.9, 0.8, 0.9],
                                }}
                                transition={{
                                    ease: "easeOut",
                                    repeat: Infinity,
                                    yoyo: Infinity,
                                    repeatDelay: 1,
                                    duration: 0.5,
                                }}
                            >
                                <SeeMoreIcon />
                            </motion.div>
                        </div>
                    </div>
                </div>
                <div id="third-slide" className="section solutions-third-slide">
                    <h1>
                        Descubre las funcionalidades de{" "}
                        <span className="animation-gradient-text">
                            SISEFARPRO
                        </span>
                    </h1>
                    <div className="solutions-third-slide__modules">
                        <div
                            onClick={() => setCurrentModuleIndex(0)}
                            className={`solutions-third-slide__module ${
                                moduleIsSelected(0) &&
                                "solutions-third-slide__module--active"
                            }`}
                        >
                            <span>Terapia Secuencial</span>
                            <video autoPlay loop muted key={"video"}>
                                <source
                                    src={backgroundVideo}
                                    type="video/mp4"
                                ></source>
                            </video>
                        </div>
                        <div
                            onClick={() => setCurrentModuleIndex(1)}
                            className={`solutions-third-slide__module ${
                                moduleIsSelected(1) &&
                                "solutions-third-slide__module--active"
                            }`}
                        >
                            <span>Ajuste de dosis por función renal</span>
                            <video autoPlay loop muted key={"video"}>
                                <source
                                    src={backgroundVideo}
                                    type="video/mp4"
                                ></source>
                            </video>
                        </div>
                        <div
                            onClick={() => setCurrentModuleIndex(2)}
                            className={`solutions-third-slide__module ${
                                moduleIsSelected(2) &&
                                "solutions-third-slide__module--active"
                            }`}
                        >
                            <span>Desescalamiento de antibióticos</span>
                            <video autoPlay loop muted key={"video"}>
                                <source
                                    src={backgroundVideo}
                                    type="video/mp4"
                                ></source>
                            </video>
                        </div>
                        <div
                            onClick={() => setCurrentModuleIndex(3)}
                            className={`solutions-third-slide__module ${
                                moduleIsSelected(3) &&
                                "solutions-third-slide__module--active"
                            }`}
                        >
                            <span>Tromboprofilaxis</span>
                            <video autoPlay loop muted key={"video"}>
                                <source
                                    src={backgroundVideo}
                                    type="video/mp4"
                                ></source>
                            </video>
                        </div>
                        <div
                            onClick={() => setCurrentModuleIndex(4)}
                            className={`solutions-third-slide__module ${
                                moduleIsSelected(4) &&
                                "solutions-third-slide__module--active"
                            }`}
                        >
                            <span>Profilaxis de Úlceras por Estrés (PUE)</span>
                            <video autoPlay loop muted key={"video"}>
                                <source
                                    src={backgroundVideo}
                                    type="video/mp4"
                                ></source>
                            </video>
                        </div>
                        <div
                            onClick={() => setCurrentModuleIndex(5)}
                            className={`solutions-third-slide__module ${
                                moduleIsSelected(5) &&
                                "solutions-third-slide__module--active"
                            }`}
                        >
                            <span>
                                Dosis Diaria Definida (DDD) y Días de
                                Tratamiento Antimicrobiano (DOT)
                            </span>
                            <video autoPlay loop muted key={"video"}>
                                <source
                                    src={backgroundVideo}
                                    type="video/mp4"
                                ></source>
                            </video>
                        </div>
                    </div>
                    <span>{descriptions[currentModuleIndex]}</span>
                    <div className="solutions-third-slide__image-container">
                        <div
                            className={`modal ${
                                currentModuleIndex === 0 && "modal--visible"
                            }`}
                        >
                            <div className="modal__body">
                                <h5>Síntomas</h5>
                                <div className="modal__body-checkboxs">
                                    {checkboxFirstModal.map((label) => {
                                        return (
                                            <Checkbox key={label}>
                                                {label}
                                            </Checkbox>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div
                            className={`modal ${
                                currentModuleIndex === 3 && "modal--visible"
                            }`}
                        >
                            <div className="modal__body">
                                <h5>Escala Padua</h5>
                                <div className="modal__body-checkboxs">
                                    {checkboxSecondModal.map((label) => {
                                        return (
                                            <Checkbox key={label}>
                                                {label}
                                            </Checkbox>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div
                            className={`modal ${
                                currentModuleIndex === 4 && "modal--visible"
                            }`}
                        >
                            <div className="modal__body">
                                <h5>Criterios</h5>
                                <div className="modal__body-checkboxs">
                                    {checkboxThirdModal.map((label) => {
                                        return (
                                            <Checkbox key={label}>
                                                {label}
                                            </Checkbox>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <img
                            onClick={nextIndex}
                            className="solutions-third-slide__image"
                            src={modules[currentModuleIndex]}
                        />
                    </div>
                </div>
            </div>

            <FooterUserComponent></FooterUserComponent>
        </div>
    );
}
