import Layout from "antd/es/layout/layout";
import { Content } from "antd/es/layout/layout";
import HeaderUserComponent from "../../components/headerUserComponent";
import FooterUserComponent from "../../components/footerUserComponent";
import {
    Pagination as AntPagination,
    PaginationProps,
    Button,
    Space,
    Modal,
} from "antd";
import { useEffect, useState } from "react";
import useToken from "../../utils/useToken";
import { LeftOutlined } from "@ant-design/icons";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./resourcesUserView.scss";

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import ResourceCard from "../../components/resourceCard/resourceCard";
import { getText, getDate } from "../../utils/utils";

export default function ResourcesUserView() {
    const tokenController = useToken();
    const [recursosData, setRecursosData] = useState([]);
    const [pdfUrl, setPdfUrl] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedResource, setSelectedResource] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxResourcesPerPage, setMaxResourcesPerPage] = useState(6);
    const [minResource, setMinResource] = useState(1);
    const [maxResource, setMaxresource] = useState(6);
    const [currentPageResources, setCurrentPageResources] = useState([]);
    const [toggleHeaderLightMode, setToggleHeaderLightMode] = useState(true);
    const [swiperRecursosData, setSwiperRecursosData] = useState([]);
    const [showBackground, setShowBackground] = useState(true);

    const getSwiperResources = async () => {
        fetch(
            "https://back-landing-sisefar.azurewebsites.net/api/resource/recent",
            {
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                },
            }
        )
            .then((res) => res.json())
            .then((body) => {
                for (let i of body) {
                    if (i["resource_type"] == 1) {
                        i["resource_type"] = "Articulo";
                    } else {
                        i["resource_type"] = "Archivo";
                    }
                }
                setSwiperRecursosData(body);
            });
    };
    const getResources = async () => {
        fetch(
            "https://back-landing-sisefar.azurewebsites.net/api/resource/all",
            {
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                },
            }
        )
            .then((res) => res.json())
            .then((body) => {
                for (let i of body) {
                    if (i["resource_type"] == 1) {
                        i["resource_type"] = "Articulo";
                    } else {
                        i["resource_type"] = "Archivo";
                    }
                }
                setRecursosData(body);
            });
    };
    useEffect(() => {
        getSwiperResources();
        getResources();
    }, []);

    let handlePageChange: PaginationProps["onChange"] = (page) => {
        setCurrentPage(page);
    };
    useEffect(() => {
        setMinResource(getMinimum(currentPage));
        setMaxresource(getMaximum(currentPage));
    }, [currentPage]);

    useEffect(() => {
        if (recursosData.length > 0 && minResource > 0 && maxResource > 0) {
            setCurrentPageResources(
                recursosData.slice(minResource - 1, maxResource)
            );
        }
    }, [minResource, maxResource, recursosData]);
    useEffect(() => {
        if (!selectedResource) {
            setToggleHeaderLightMode(true);
            setShowBackground(true);
        } else {
            setToggleHeaderLightMode(false);
            setShowBackground(false);
        }
    }, [selectedResource]);

    let getMinimum = (currentPage: number) => {
        return (currentPage - 1) * maxResourcesPerPage + 1;
    };
    let getMaximum = (currentPage: number) => {
        return currentPage * maxResourcesPerPage;
    };

    return (
        <Layout style={{ backgroundColor: "white" }}>
            <HeaderUserComponent lightMode={toggleHeaderLightMode} />
            {selectedResource ? (
                <Content
                    className="main-content"
                    style={{ paddingLeft: "10%", paddingRight: "10%" }}
                >
                    <div
                        className="selected-resource-header"
                        style={{
                            backgroundImage: `linear-gradient(to top, #1E4243b3, #1E4243b3), url(${selectedResource.cover_image})`,
                        }}
                    >
                        <Button
                            className="selected-resource-back-button"
                            ghost
                            onClick={() => {
                                setSelectedResource(null);
                                setToggleHeaderLightMode(
                                    !toggleHeaderLightMode
                                );
                            }}
                        >
                            <LeftOutlined />
                            Regresar
                        </Button>
                        <div className="selected-resource-header-data">
                            <div className="selected-resource-header-data-title">
                                {selectedResource.title || "Título Default"}
                            </div>
                            <div className="selected-resource-header-data-author">
                                Por {selectedResource.author || "Autor Default"}
                            </div>
                        </div>
                    </div>
                    <div className="selected-resource-content">
                        <div className="selected-resource-content-data">
                            <div className="selected-resource-content-date">
                                {getDate(selectedResource.date_created) ||
                                    "N/A"}
                            </div>
                            <div className="selected-resource-content-header">
                                <div className="selected-resource-content-header-title">
                                    {selectedResource.title || "Título Default"}
                                </div>
                                <div className="selected-resource-content-header-author">
                                    Por{" "}
                                    {selectedResource.author || "Autor Default"}
                                </div>
                            </div>
                        </div>
                        <div
                            className="selected-resource-content-body"
                            dangerouslySetInnerHTML={{
                                __html: selectedResource.content,
                            }}
                        />
                    </div>
                </Content>
            ) : (
                <Content className="main-content">
                    <div className="top-resources-container">
                        <Swiper
                            navigation={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Navigation, Pagination]}
                            className="mySwiper"
                            slidesPerView={1}
                            spaceBetween={30}
                        >
                            {swiperRecursosData.map((resource) => (
                                <SwiperSlide key={resource["id"]}>
                                    <div className="resource">
                                        <div className="imageContainer">
                                            <img
                                                src={resource["cover_image"]}
                                                alt="test"
                                            />
                                            <div className="resource-data">
                                                <div className="resource-item-title">
                                                    {resource["title"] || "N/A"}
                                                </div>
                                                <div className="resource-item-content">
                                                    {getText(
                                                        resource["content"]
                                                    ) || "N/A"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="resources-body">
                        <div className="resources-container-grid">
                            {currentPageResources.map((recurso) => (
                                <div
                                    className="resource-card-item"
                                    key={recurso["id"]}
                                >
                                    <ResourceCard
                                        title={recurso["title"]}
                                        description={recurso["content"]}
                                        coverImage={recurso["cover_image"]}
                                        author={recurso["author"]}
                                        resourceType={recurso["resource_type"]}
                                        resource={recurso}
                                        setPdfUrl={setPdfUrl}
                                        setIsModalOpen={setIsModalOpen}
                                        setSelectedResource={
                                            setSelectedResource
                                        }
                                        toggleHeaderLightMode={
                                            toggleHeaderLightMode
                                        }
                                        setToggleHeaderLightMode={
                                            setToggleHeaderLightMode
                                        }
                                        publishedDate={getDate(
                                            recurso["date_created"]
                                        )}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="resources-pagination">
                            <AntPagination
                                current={currentPage}
                                defaultCurrent={currentPage}
                                defaultPageSize={maxResourcesPerPage}
                                total={recursosData.length}
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </Content>
            )}
            <Modal
                width={700}
                title="Visualizador Articulo"
                open={isModalOpen}
                onOk={() => {
                    setIsModalOpen(false);
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                }}
            >
                <Space>
                    <iframe
                        src={`https://docs.google.com/viewer?url=${pdfUrl}&embedded=true`}
                        height="450px"
                        width="650px"
                    ></iframe>
                </Space>
            </Modal>
            <FooterUserComponent />
        </Layout>
    );
}
