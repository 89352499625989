import { Input, Button, notification } from "antd";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useToken from "../../utils/useToken";

export default function LoginAdminView() {
    const navigator = useNavigate();

    const { token, setToken } = useToken();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    async function loginUser(credentials: {
        username: string;
        password: string;
    }) {
        let formdata = new FormData();
        formdata.append("username", credentials.username);
        formdata.append("password", credentials.password);
        return fetch("https://back-landing-sisefar.azurewebsites.net/token", {
            method: "POST",
            body: formdata,
        }).then((res) => res.json());
    }

    const handleLogin = async () => {
        const res = await loginUser({ username: email, password });
        if (res["detail"] == null) {
            setToken(res);
            navigator("/admin/home");
        } else {
            notification.error({
                message: "usario o contraseña incorrectos",
            });
        }
    };

    useEffect(() => {
        if (token !== undefined) {
            navigator("/admin/home");
        }
    }, []);

    return (
        <Content
            style={{
                backgroundColor: "#000336",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div
                style={{
                    backgroundColor: "white",
                    width: "40%",
                    height: "60vh",
                    borderRadius: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <img
                    onClick={() => {
                        navigator("/");
                    }}
                    src={require("../../assets/logo_sisefar.png")}
                    style={{
                        width: "65%",
                        marginTop: "2%",
                        cursor: "pointer",
                    }}
                ></img>
                <div
                    style={{
                        fontFamily: "Manrope,sans-serif",
                        fontSize: 25,
                        fontWeight: 700,
                        letterSpacing: "-0.07em",
                        lineHeight: "0.91em",
                        color: "#000336",
                    }}
                >
                    Admin Panel
                </div>
                <div
                    style={{
                        width: "65%",
                        marginTop: "10%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <label
                        style={{
                            fontFamily:
                                "Manrope,Manrope Placeholder,sans-serif",
                            fontSize: 17,
                            fontWeight: 300,
                            letterSpacing: "0em",
                            lineHeight: "1.7em",
                            color: "#2f9a9b",
                        }}
                    >
                        Correo Electronico:
                    </label>
                    <Input
                        onChange={(e: any) => setEmail(e.target.value)}
                        style={{ marginBottom: "3%" }}
                    ></Input>
                    <label
                        style={{
                            fontFamily:
                                "Manrope,Manrope Placeholder,sans-serif",
                            fontSize: 17,
                            fontWeight: 300,
                            letterSpacing: "0em",
                            lineHeight: "1.7em",
                            color: "#2f9a9b",
                        }}
                    >
                        Contraseña:
                    </label>
                    <Input
                        type="password"
                        onChange={(e: any) => setPassword(e.target.value)}
                        style={{ marginBottom: "3%" }}
                    ></Input>
                    <Button
                        onClick={handleLogin}
                        style={{
                            fontFamily:
                                "Manrope,Manrope Placeholder,sans-serif",
                            fontSize: 17,
                            fontWeight: 300,
                            color: "#2f9a9b",
                        }}
                    >
                        Entrar
                    </Button>
                </div>
            </div>
        </Content>
    );
}
