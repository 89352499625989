import { useState, useEffect } from "react";
import Layout, { Content } from "antd/es/layout/layout";
import { Space, Table, Button, Modal, Input, notification, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import HeaderAdminComponent from "../../components/headerAdminComponent";
import useToken from "../../utils/useToken";

const { TextArea } = Input;
export default function HomeAdminView() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formReady, setFormReady] = useState(true);
    const [description, setDescription] = useState("");
    const [name, setName] = useState("");
    const [organization, setOrganization] = useState("");
    const navigator = useNavigate();

    useEffect(() => {
        if (description === "" || name === "" || organization === "") {
            setFormReady(true);
        } else {
            setFormReady(false);
        }
    }, [description, name, organization]);

    const clearForm = () => {
        setDescription("");
        setName("");
        setOrganization("");
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        fetch(
            "https://back-landing-sisefar.azurewebsites.net/api/impact/create",
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    description,
                    image_url: "",
                    publisher_name: name,
                    publisher_role: organization,
                }),
            }
        )
            .then((res) => res.json())
            .then(() => {
                setIsModalOpen(false);
                clearForm();
                notification.success({
                    message: "Impacto agregado exitosamente!!",
                });
            });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleClick = () => {
        navigator("/admin/new-resource");
    };

    const tokenController = useToken();
    const [impactosData, setImpactosData] = useState([]);
    const [contactosData, setContactosData] = useState([]);
    const [recursosData, setRecursosData] = useState([]);
    const [bookedDemoData, setBookedDemoData] = useState([]);
    const rows = 50;

    const changeVisibilityImpacto = (
        impacto_id: number,
        is_public: boolean
    ) => {
        fetch(
            `https://back-landing-sisefar.azurewebsites.net/api/impact/change_visibility/${impacto_id}`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    is_public,
                }),
            }
        )
            .then((res) => res.json())
            .then(async () => {
                notification.success({
                    message: "Visibilidad cambiada con exito",
                });
                await getImpacts();
            });
    };

    const deleteImpacto = (impacto_id: number) => {
        fetch(
            `https://back-landing-sisefar.azurewebsites.net/api/impact/${impacto_id}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                },
            }
        )
            .then((res) => res.json())
            .then(async () => {
                notification.success({
                    message: "Impacto eliminado con exito",
                });
                await getImpacts();
            });
    };

    const impactosColumns = [
        {
            title: "Publicador",
            dataIndex: "publisher_name",
            key: "publisher_name",
        },
        {
            title: "Rol Publicador",
            dataIndex: "publisher_role",
            key: "publisher_role",
        },
        {
            title: "Descripcion",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Acciones",
            key: "action",
            render: (_: any, record: any) => {
                return (
                    <Space size="middle">
                        <Switch
                            onChange={(checked) => {
                                changeVisibilityImpacto(record.id, checked);
                            }}
                            checkedChildren="Publico"
                            unCheckedChildren="Privado"
                            checked={record.is_public}
                        ></Switch>
                        <Button onClick={() => deleteImpacto(record.id)} danger>
                            Eliminar
                        </Button>
                    </Space>
                );
            },
        },
    ];

    const getImpacts = async () => {
        fetch("https://back-landing-sisefar.azurewebsites.net/api/impact/all", {
            headers: {
                Authorization: `Bearer ${tokenController.getToken()}`,
            },
        })
            .then((res) => res.json())
            .then((body) => setImpactosData(body));
    };

    const getContacts = async () => {
        fetch(
            "https://back-landing-sisefar.azurewebsites.net/api/contact/all",
            {
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                },
            }
        )
            .then((res) => res.json())
            .then((body) => {
                for (let i of body) {
                    let date = new Date(i["created_at"]);
                    i["created_at"] =
                        date.getDate() +
                        "/" +
                        (date.getMonth() + 1) +
                        "/" +
                        date.getFullYear();
                }
                setContactosData(body);
            });
    };

    const getBookedDemos = async () => {
        fetch(
            "https://back-landing-sisefar.azurewebsites.net/api/booked_demo/all",
            {
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                },
            }
        )
            .then((res) => res.json())
            .then((body) => {
                for (let bookedDemo of body) {
                    let date = new Date(bookedDemo["booked_at"]);
                    let hours = date.getHours() + 6;
                    let period = hours >= 12 ? "pm" : "am";

                    if (hours > 12) {
                        hours -= 12;
                    }
                    bookedDemo["booked_at"] =
                        date.getDate() +
                        "/" +
                        (date.getMonth() + 1) +
                        "/" +
                        date.getFullYear() +
                        " " +
                        hours +
                        ":" +
                        date.getMinutes().toString().padStart(2, "0") +
                        " " +
                        period;
                }

                setBookedDemoData(body);
            });
    };

    const getResources = async () => {
        fetch(
            "https://back-landing-sisefar.azurewebsites.net/api/resource/all",
            {
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                },
            }
        )
            .then((res) => res.json())
            .then((body) => {
                for (let i of body) {
                    if (i["resource_type"] == 1) {
                        i["resource_type"] = "Articulo Informativo";
                    } else {
                        i["resource_type"] = "Articulo";
                    }
                }
                setRecursosData(body);
            });
    };

    const deleteResource = (resource_id: number) => {
        fetch(
            `https://back-landing-sisefar.azurewebsites.net/api/resource/${resource_id}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                },
            }
        )
            .then((res) => res.json())
            .then(async () => {
                notification.success({
                    message: "Recurso eliminado con exito",
                });
                await getResources();
            });
    };

    const changeVisibilityResource = (
        resource_id: number,
        is_public: boolean
    ) => {
        fetch(
            `https://back-landing-sisefar.azurewebsites.net/api/resource/change_visibility/${resource_id}`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    is_public,
                }),
            }
        )
            .then((res) => res.json())
            .then(async () => {
                notification.success({
                    message: "Visibilidad cambiada con exito",
                });
                await getResources();
            });
    };

    useEffect(() => {
        getImpacts();
        getContacts();
        getResources();
        getBookedDemos();
    }, []);

    useEffect(() => {
        getImpacts();
    }, [isModalOpen]);

    const contactosColumns = [
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Correo",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Telefono",
            dataIndex: "phone_number",
            key: "phone_number",
        },
        {
            title: "Fecha",
            dataIndex: "created_at",
            key: "created_at",
        },
    ];

    const bookedDemoColumns = [
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Correo",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Teléfono",
            dataIndex: "phone_number",
            key: "phone_number",
        },
        {
            title: "Fecha",
            dataIndex: "booked_at",
            key: "booked_at",
        },
    ];

    const recursosColumns = [
        {
            title: "Titulo",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Tipo",
            dataIndex: "resource_type",
            key: "resource_type",
        },
        {
            title: "Autor",
            dataIndex: "author",
            key: "author",
        },
        {
            title: "Acciones",
            key: "action",
            render: (_: any, record: any) => (
                <Space size="middle">
                    <Switch
                        onChange={(checked) => {
                            changeVisibilityResource(record.id, checked);
                        }}
                        checkedChildren="Publico"
                        unCheckedChildren="Privado"
                        checked={record.is_public}
                    ></Switch>
                    <Button onClick={() => deleteResource(record.id)} danger>
                        Eliminar
                    </Button>
                </Space>
            ),
        },
    ];

    const customHeaderImpacto = (
        <div
            style={{
                display: "flex",
            }}
        >
            <div className="ant-table-title">Impactos</div>
            <div
                style={{
                    flexGrow: 4,
                }}
            />
            <div className="ant-table-title">
                <Button onClick={showModal}>Agregar</Button>
            </div>
        </div>
    );

    const customHeaderRecurso = (
        <div
            style={{
                display: "flex",
            }}
        >
            <div className="ant-table-title">Recursos</div>
            <div
                style={{
                    flexGrow: 4,
                }}
            />
            <div className="ant-table-title">
                <Button onClick={handleClick}>Agregar</Button>
            </div>
        </div>
    );

    const customHeaderBookedDemo = (
        <div>
            <div className="ant-table-title">Demos Agendadas</div>
        </div>
    );

    return (
        <Layout style={{ backgroundColor: "white" }}>
            <HeaderAdminComponent></HeaderAdminComponent>
            <div style={{ height: "4rem" }}></div>
            <Content className="main-content">
                <Table
                    dataSource={impactosData}
                    columns={impactosColumns}
                    pagination={{
                        pageSize: 10,
                    }}
                    scroll={{
                        y: 240,
                    }}
                    bordered
                    title={() => customHeaderImpacto}
                />
                <Table
                    dataSource={contactosData}
                    columns={contactosColumns}
                    pagination={{
                        pageSize: 10,
                    }}
                    scroll={{
                        y: 240,
                    }}
                    bordered
                    title={() => "Contactos"}
                />
                <Table
                    dataSource={bookedDemoData}
                    columns={bookedDemoColumns}
                    pagination={{
                        pageSize: 10,
                    }}
                    scroll={{
                        y: 240,
                    }}
                    bordered
                    title={() => "Demos Agendadas"}
                />
                <Table
                    dataSource={recursosData}
                    columns={recursosColumns}
                    pagination={{
                        pageSize: 10,
                    }}
                    scroll={{
                        y: 240,
                    }}
                    bordered
                    title={() => customHeaderRecurso}
                />
            </Content>
            <Modal
                title="Agregar Impacto:"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1000}
                okButtonProps={{
                    disabled: formReady,
                }}
            >
                <Space
                    direction="vertical"
                    style={{ width: "100%" }}
                    size={"large"}
                >
                    <Layout>
                        <Layout>
                            <Content>
                                <Space
                                    direction="vertical"
                                    style={{ padding: "1.5%", width: "50%" }}
                                >
                                    <label>Descripcion *</label>
                                    <TextArea
                                        rows={4}
                                        value={description}
                                        onChange={(e) =>
                                            setDescription(
                                                e.currentTarget.value
                                            )
                                        }
                                        style={{
                                            marginBottom: "3%",
                                        }}
                                    ></TextArea>
                                    <label>Nombre *</label>
                                    <Input
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.currentTarget.value)
                                        }
                                        style={{
                                            marginBottom: "3%",
                                        }}
                                    ></Input>
                                    <label>Rol en organizacion *</label>
                                    <Input
                                        value={organization}
                                        onChange={(e) =>
                                            setOrganization(
                                                e.currentTarget.value
                                            )
                                        }
                                        style={{
                                            marginBottom: "3%",
                                        }}
                                    ></Input>
                                </Space>
                            </Content>
                        </Layout>
                    </Layout>
                </Space>
            </Modal>
        </Layout>
    );
}
