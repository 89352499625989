import HeaderUserComponent from "../../components/headerUserComponent";
import FooterUserComponent from "../../components/footerUserComponent";
import ColombiaFlag from "../../assets/images/colombia_flag.png";
import PalmiraImage from "../../assets/images/palmira_01.png";
import MexicoFlag from "../../assets/images/mexico_flag.png";
import ChileFlag from "../../assets/images/chile_flag.png";
import NicoImage from "../../assets/images/nico_01.png";
import JhonImage from "../../assets/images/jhon_01.png";
import { Swiper as SwiperClass } from "swiper/types";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCallback, useRef } from "react";
import { EffectFade } from "swiper/modules";
import { v4 as uuidv4 } from "uuid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Layout } from "antd";
import "./teamUserView.css";
import "../../styles.scss";
import "swiper/css";

export default function TeamUserView() {
    const swiperRefMembers: any = useRef();
    const swiperRefInformation: any = useRef();

    const previousMember = useCallback(() => {
        swiperRefMembers.current!.slidePrev();
        swiperRefInformation.current!.slidePrev();
    }, [swiperRefMembers, swiperRefInformation]);

    const nextMember = useCallback(() => {
        swiperRefMembers.current!.slideNext();
        swiperRefInformation.current!.slideNext();
    }, [swiperRefMembers]);

    let team = [
        {
            id: "palmira-photo",
            name: "Palmira Muñoz",
            image: PalmiraImage,
            title: "Farmacéutica Clínica",
            description:
                "Enfocada en farmacia clínica y farmacia hospitalaria. Además de la gestión de procesos y atención centrada en la seguridad del paciente.",
            flag: MexicoFlag,
        },
        {
            id: "",
            name: "Nicolás Severino",
            image: NicoImage,
            title: "Farmacéutico Clínico",
            description:
                "Con más de 10 años de experiencia en la farmacia clínica y la atención farmacéutica en departamento de Medicina Intensiva. Investigaciones en farmacocinética de antibióticos y evaluación del dolor y analgesia.",
            flag: ChileFlag,
        },
        {
            id: "",
            name: "Jhon Echeverry",
            image: JhonImage,
            title: "Farmacéutico Clínico",
            description:
                "En su trayectoria profesional con más de 10 años de experiencia en la farmacia clínica y farmacia hospitalaria. Gestión y liderazgo de procesos en manejo y uso de medicamentos, calidad y seguridad del paciente. Implementación de planes PROA.",
            flag: ColombiaFlag,
        },
    ];

    return (
        <Layout style={{ backgroundColor: "white" }}>
            <HeaderUserComponent lightMode={true}></HeaderUserComponent>
            <div id="team-container">
                <div id="team-information">
                    <h4>Quiénes somos</h4>
                    <h2>
                        Conoce al equipo de <br></br>
                        <span className="animation-gradient-text">
                            farmacéuticos clínicos{" "}
                        </span>
                        que <br></br>
                        hizo posible esta plataforma.
                    </h2>
                </div>
                <div id="team-members-container">
                    <button
                        id="prev-member-button"
                        onClick={previousMember}
                    ></button>
                    <div id="team-members">
                        <div className="member-information-container glass-background">
                            <Swiper
                                style={{ overflow: "visible" }}
                                loop={true}
                                effect="fade"
                                speed={1000}
                                autoHeight={true}
                                watchOverflow={true}
                                fadeEffect={{ crossFade: true }}
                                modules={[EffectFade]}
                                onSwiper={(swiper: SwiperClass) => {
                                    swiperRefInformation.current = swiper;
                                }}
                            >
                                {team.map((member: any) => (
                                    <SwiperSlide key={uuidv4()}>
                                        <div className="member-information">
                                            <div className="flag">
                                                <img src={member.flag}></img>
                                            </div>
                                            <div>
                                                <h1>{member.name}</h1>
                                                <h3>{member.title}</h3>
                                            </div>
                                            <span>{member.description}</span>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div id="team-members-carrousel">
                            <Swiper
                                loop={true}
                                speed={1000}
                                watchSlidesProgress={true}
                                onSwiper={(swiper: SwiperClass) => {
                                    swiperRefMembers.current = swiper;
                                }}
                            >
                                {team.map((member: any) => (
                                    <SwiperSlide>
                                        <div className="member-container">
                                            <img
                                                id={member.id}
                                                src={member.image}
                                            ></img>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                    <button
                        id="next-member-button"
                        onClick={nextMember}
                    ></button>
                </div>
            </div>
            <FooterUserComponent></FooterUserComponent>
        </Layout>
    );
}
