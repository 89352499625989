import { DateFormatter, DayPicker } from "react-day-picker";
import { useState, useEffect } from "react";
import useToken from "../../utils/useToken";
import "react-day-picker/dist/style.css";
import { es } from "date-fns/locale";
import { notification } from "antd";
import "./modalDemoFormStyles.scss";
import { format } from "date-fns";
import { Input } from "antd";
import "../../styles.scss";

export default function ModalDemoForm() {
    const tokenController = useToken();
    const [diasNoDisponibles, setDiasNoDisponibles] = useState<Date[]>([]);

    const [formNotReady, setFormNotReady] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [dateSelected, setDateSelected] = useState<Date>();
    const [hour, setHour] = useState("");
    const [dateTime, setDateTime] = useState("");
    const hourMinimumLimit = "10:00:00";
    const hourMaximumLimit = "16:00:00";

    const closeModal = () => {
        clearForm();
        const modalDemo = document.getElementById("modal-demo-form");
        if (modalDemo === null) return;
        modalDemo.style.display = "none";
    };

    const clearForm = () => {
        setName("");
        setPhone("");
        setEmail("");
        setDateSelected(undefined);
        setHour("");
        setDateTime("");
    };

    const bookDate = () => {
        if (canSendBook())
            fetch(
                "https://back-landing-sisefar.azurewebsites.net/api/booked_demo/create",
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${tokenController.getToken()}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name,
                        email,
                        phone_number: phone,
                        booked_at: dateTime,
                    }),
                }
            )
                .then((res) => res.json())
                .then(() => {
                    notification.success({
                        message: "Demo agendada exitosamente.",
                    });
                    closeModal();
                });
    };

    useEffect(() => {
        if (
            name === "" ||
            !email.includes("@") ||
            email === "" ||
            (phone !== "" && isNaN(Number(phone))) ||
            !document.querySelector(".rdp-day_selected") ||
            hour === ""
        ) {
            setFormNotReady(true);
        } else {
            setFormNotReady(false);
        }
    }, [name, email, phone, dateSelected, hour]);

    useEffect(() => {
        if (dateSelected !== undefined) {
            const date = getDate(dateSelected);
            setDateTime(`${date} ${hour}`);
        }
    }, [dateSelected, hour]);

    const getDate = (dateToGet: Date) => {
        const date = new Date(dateToGet);
        const year = date.getFullYear();
        const month = "0" + (date.getMonth() + 1);
        const day = "0" + date.getDate();
        return `${year}-${month.slice(-2)}-${day.slice(-2)}`;
    };

    const getBookedDates = () => {
        fetch(
            "https://back-landing-sisefar.azurewebsites.net/api/booked_demo/all",
            {
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                },
            }
        )
            .then((res) => res.json())
            .then((body) => {
                let aux = [];
                for (let booked of body) {
                    aux.push(new Date(booked["booked_at"]));
                }
                setDiasNoDisponibles(aux);
            });
    };

    useEffect(() => {
        getBookedDates();
    }, [formNotReady]);

    const scheduledHourIsValid = (): boolean => {
        return hour >= hourMinimumLimit && hour <= hourMaximumLimit;
    };

    const canSendBook = (): boolean => {
        return (
            name !== "" &&
            email !== "" &&
            phone !== "" &&
            dateTime !== "" &&
            hour !== "" &&
            scheduledHourIsValid()
        );
    };

    const formatWeekdayName: DateFormatter = (date, options) => (
        <>{format(new Date(date), "EEE", { locale: options?.locale })}</>
    );

    return (
        <div
            id="modal-demo-form"
            className="custom-modal"
            style={{ display: "none" }}
        >
            <div id="modal-form-body">
                <div id="modal-form-header">
                    <h4>Solicita un demo</h4>
                    <span
                        onClick={closeModal}
                        className="material-symbols-rounded"
                    >
                        close
                    </span>
                </div>

                <div>
                    <div id="modal-form-daypicker">
                        <DayPicker
                            locale={es}
                            mode="single"
                            weekStartsOn={0}
                            selected={dateSelected}
                            onSelect={setDateSelected}
                            formatters={{ formatWeekdayName }}
                            showOutsideDays
                            fixedWeeks
                            disabled={diasNoDisponibles}
                            modifiersClassNames={{
                                disabled: "notAvailable",
                            }}
                            modifiersStyles={{
                                disabled: { fontSize: "80%" },
                            }}
                        />
                        <div id="select-hour">
                            <label>Hora:</label>
                            <input
                                type="time"
                                id="demoHour"
                                name="demoHour"
                                onChange={(e) =>
                                    setHour(e.target.value + ":00")
                                }
                                value={hour}
                            />
                            <span>Elige un horario entre 10 am y 4 pm</span>
                        </div>
                    </div>

                    <div id="modal-form-inputs">
                        <div>
                            <label>Nombre(s):</label>
                            <Input
                                value={name}
                                onChange={(e) => setName(e.currentTarget.value)}
                                style={{
                                    marginBottom: "3%",
                                }}
                            ></Input>
                            <label>Correo Electrónico:</label>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) =>
                                    setEmail(e.currentTarget.value)
                                }
                                style={{
                                    marginBottom: "3%",
                                }}
                            ></Input>
                            <label>Teléfono:</label>
                            <Input
                                type="tel"
                                value={phone}
                                onChange={(e) =>
                                    setPhone(e.currentTarget.value)
                                }
                                style={{
                                    marginBottom: "3%",
                                }}
                            ></Input>
                        </div>
                        <button
                            style={{
                                background: `${
                                    canSendBook() ? "#329B99" : "#b7dfd9"
                                }`,
                                cursor: `${
                                    canSendBook() ? "pointer" : "default"
                                }`,
                            }}
                            onClick={bookDate}
                        >
                            Enviar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
