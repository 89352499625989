import { useState } from "react";
export default function useToken() {
    const getToken = (): string | undefined => {
        const tokenString = localStorage.getItem("token");

        if (tokenString === null) return undefined;

        return JSON.parse(tokenString);
    };

    const getUser = () => {
        const userString = localStorage.getItem("user");
        const user = JSON.parse(userString!);
        return user;
    };

    const [token, setToken] = useState(getToken());
    //const [user, setUser] = useState({})

    const saveToken = (userToken: { access_token: string }) => {
        localStorage.setItem("token", JSON.stringify(userToken.access_token));
        setToken(userToken.access_token);
    };

    const clearToken = () => {
        localStorage.removeItem("token");
    };

    return {
        setToken: saveToken,
        token,
        clearToken,
        getUser,
        getToken,
    };
}
