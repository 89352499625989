import { ReactComponent as LinkedinIcon } from "../assets/icons/linkedin_icon.svg";
import ModalContactForm from "./modalContactForm/modalContactForm";
import { NavigateFunction, useNavigate } from "react-router-dom";
import logo from "../assets/images/sisefar_logo_01.png";
import { Footer } from "antd/es/layout/layout";
import { motion } from "framer-motion";
import { useState } from "react";
import "./footerStyles.scss";
import "../styles.scss";

export default function FooterUserComponent() {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const navigate: NavigateFunction = useNavigate();

    return (
        <>
            <Footer id="footer">
                <div>
                    <div>
                        <motion.img
                            whileHover={{
                                width: "45%",
                            }}
                            onClick={(e) => navigate("/")}
                            style={{ width: "40%", cursor: "pointer" }}
                            src={logo}
                        ></motion.img>
                        <span>
                            Una plataforma por farmacéuticos, <br></br> para
                            farmacéuticos
                        </span>
                    </div>
                    <h5>
                        Made by{" "}
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                window.open("https://christuscei.mx/")
                            }
                        >
                            CHRISTUS CEI
                        </span>
                    </h5>
                </div>
                <div>
                    <a href="/">Inicio</a>
                    <a href="/soluciones">Soluciones</a>
                    <a href="/equipo">Equipo</a>
                    <a href="/recursos">Recursos</a>
                    <a href="/comunidad">Comunidad</a>
                </div>
                <div>
                    <h3>¿Quieres saber más?</h3>
                    <span>
                        Deja tus datos y te compartiremos más información
                    </span>
                    <div id="footer-contact">
                        <button
                            onClick={() => setModalIsOpen(true)}
                            className="splash-button"
                        >
                            Contáctanos
                        </button>
                        <div
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/sisefar-pro-8701a8284/"
                                )
                            }
                        >
                            <LinkedinIcon />
                            <h4>SISEFAR PRO</h4>
                        </div>
                    </div>
                </div>
            </Footer>{" "}
            <ModalContactForm
                open={modalIsOpen}
                setOpen={() => setModalIsOpen(false)}
            ></ModalContactForm>
        </>
    );
}
