import { Avatar, Button, Menu, Popover } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./header.scss";
import { Header } from "antd/es/layout/layout";
import useToken from "../utils/useToken";
export default function HeaderAdminComponent() {
    const navigate = useNavigate();
    const tokenController = useToken();
    const handleSignout = () => {
        tokenController.clearToken();
        navigate("/admin/login");
        window.location.reload();
    };
    const [items, setItems] = useState([]);
    const [user, setUser] = useState("");

    /*useEffect(() => {
        let user = getUser()
        setUser(user.name)
        if (user.permissions.includes(3)) {
            setItems([...items, {
                label: "Usuarios",
                key: 'users'
            }])
        }
    }, [])*/

    return (
        <Header id="header-container">
            <div id="main-container">
                <div id="logo-container">
                    <img
                        onClick={(e) => navigate("/")}
                        style={{ width: "60%", cursor: "pointer" }}
                        src={require("../assets/logo_sisefar.png")}
                    ></img>
                </div>
                <div id="menu-container">
                    <Menu
                        id="menu-style"
                        onClick={(e) => {
                            navigate(`/${e.key}`);
                        }}
                        mode="horizontal"
                        items={items}
                    ></Menu>
                    <Button
                        onClick={handleSignout}
                        size="large"
                        id="menu-button-demo"
                    >
                        Logout
                    </Button>
                </div>
            </div>
        </Header>
    );
}
